import {
  Component,
  EventEmitter,
  Input,
  numberAttribute,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PagerService } from '@services/pager.service';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-paginator',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatSelectModule],
  providers: [PagerService],
  templateUrl: './paginator.component.html',
  styleUrl: './paginator.component.scss',
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() items!: Array<any>;
  @Input({ transform: numberAttribute }) totalItems: number = 0;
  @Input() pageSize: number = 10;
  @Input() pageSizeOptions: number[] = [];
  @Input() sort: any;
  @Input() initialPage: number = 1;
  @Input() hasMoreItems?: boolean;

  @Output() changePage = new EventEmitter<any>(true);

  pager: any = {};

  constructor(private pagerService: PagerService) {}

  ngOnInit() {
    // set page if items array isn't empty
    this.getPagination(this.initialPage, false);
  }

  ngOnChanges(changes: SimpleChanges) {
    // reset page if items array has changed
    if (changes['items'] !== undefined) {
      if (changes['items'].currentValue !== changes['items'].previousValue) {
        this.setPage(this.initialPage);
      }
    } else if (changes['totalItems'] !== undefined) {
      if (
        changes['totalItems'].currentValue !==
        changes['totalItems'].previousValue
      ) {
        this.getPagination(this.initialPage, false);
      }
    } else if (changes['sort'] !== undefined) {
      if (this.totalItems) {
        this.pager = this.pagerService.getPager(
          this.totalItems,
          this.initialPage,
          this.pageSize,
        );
      }
    } else if (changes['pageSize'] !== undefined) {
      if (
        changes['pageSize'].currentValue !== changes['pageSize'].previousValue
      ) {
        this.getPagination(this.initialPage, false);
      }
    }
  }

  setPage(page: number) {
    // get new pager object for specified page
    this.pager = this.pagerService.getPager(
      this.totalItems,
      page,
      this.pageSize,
    );

    // get new page of items from items array
    const pageOfItems = this.items?.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1,
    );

    // call change page function in parent component
    this.changePage.emit(pageOfItems);
  }

  getPagination(page: number, emitChange?: boolean) {
    this.pager = this.pagerService.getPager(
      this.totalItems,
      page,
      this.pageSize,
    );
    emitChange = emitChange === undefined ? true : emitChange;

    if (this.pager.startIndex >= 0 && emitChange) {
      this.changePage.emit({
        skipCount: this.pager.startIndex,
        maxItems: this.pageSize,
      });
    }
  }

  getRangeStart(): number {
    if (this.totalItems) {
      return (this.pager.currentPage - 1) * this.pageSize + 1;
    } else {
      return this.pager.currentPage * this.pageSize;
    }
  }

  getRangeEnd(): number {
    const end = this.pager.currentPage * this.pageSize;
    return end > this.totalItems ? this.totalItems : end;
  }

  goToPage(page: number): void {
    this.pager.currentPage = page;

    this.changePage.emit({
      pageIndex: this.pager.currentPage,
      pageSize: this.pageSize,
      length: this.totalItems,
    });
  }

  goToPreviousPage(): void {
    if (this.pager.currentPage > 0) {
      this.pager.currentPage = this.pager.currentPage - 1;

      this.changePage.emit({
        pageIndex: this.pager.currentPage,
        pageSize: this.pageSize,
        length: this.totalItems,
      });
    }
  }

  goToNextPage(): void {
    if (this.pager.currentPage < this.totalPages) {
      if (this.pager.currentPage > 0 || this.pager.currentPage === 1) {
        this.pager.currentPage = this.pager.currentPage + 1;
      }

      this.changePage.emit({
        pageIndex: this.pager.currentPage,
        pageSize: this.pageSize,
        length: this.totalItems,
      });
    }
  }

  get totalPages(): number {
    return Math.ceil(this.totalItems / this.pageSize);
  }

  getPages(): (number | '...')[] {
    const pages: (number | '...')[] = [];
    const total = this.totalPages;

    if (total <= 8) {
      for (let i = 1; i <= total; i++) {
        pages.push(i);
      }
    } else {
      if (this.pager.currentPage <= 5) {
        for (let i = 1; i <= 5; i++) {
          pages.push(i);
        }
        pages.push('...');
        for (let i = total - 2; i <= total; i++) {
          pages.push(i);
        }
      } else if (this.pager.currentPage >= total - 4) {
        for (let i = 1; i <= 3; i++) {
          pages.push(i);
        }
        pages.push('...');
        for (let i = total - 4; i <= total; i++) {
          pages.push(i);
        }
      } else {
        pages.push(1);
        pages.push('...');
        for (
          let i = this.pager.currentPage - 1;
          i <= this.pager.currentPage + 1;
          i++
        ) {
          pages.push(i);
        }
        pages.push('...');
        for (let i = total - 1; i <= total; i++) {
          pages.push(i);
        }
      }
    }

    return pages;
  }

  selectPageSize(pageSize: number): void {
    this.pageSize = pageSize;
    this.pager.currentPage = 1;

    this.changePage.emit({
      pageIndex: this.pager.currentPage,
      pageSize: this.pageSize,
      length: this.totalItems,
    });
  }
}
